@tailwind base;
@tailwind components;
@tailwind utilities;



html,
body {
  /* overflow: hidden; */
  width: 100%;
  height: 100vh;
  max-height: 100%;
  margin: 0;
  padding: 0;
}

textarea {
  color: white;
  -webkit-text-fill-color: white;
  -webkit-opacity: 1;
  font-size: 16px !important;
}

::placeholder {
  color: white;
  opacity: 1;
}

::-ms-input-placeholder {
  color: white;
}

@media only screen and (max-width:450px) and (-webkit-min-device-pixel-ratio:0) {

  select:focus,
  textarea:focus,
  input:focus {
    font-size: 16px !important;
  }
}

@media (orientation: landscape) {
  .video-container {
    flex-direction: row;
    display: flex;
    flex-wrap: wrap;
    overflow: auto;
  }
}

@media (orientation: portrait) {
  .video-container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    overflow: auto;
  }

  .video {
    width: 100%;
  }
}


*::-webkit-media-controls-start-playback-button {
  display: none !important;
  -webkit-appearance: none;
}

.button:active {
  transform: translateY(1px);
}